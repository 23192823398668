@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400i|Roboto');

* {
    font-family: 'Roboto', sans-serif;
}

.light {
    color : $color-font-light;
}

.dark {
    color : $color-font-dark;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Playfair Display', serif;
    color       : $color-font-secondary;
    font-style: italic;
}

// h1 { }

//h2 { }

//h3 { }

//h4 { }

//h5 { }

//h6 { }

p {
    margin      : 0 0 15px;
    color       : $color-font-dark;
    font-weight : lighter;
    font-size   : 16px;
    line-height : 25px;
}

a {
    color           : $color-font-primary;
    text-decoration : none !important;
    
    &:hover {
        color : $color-primary;
    }
}

b, strong {
    font-weight : bold;
}

i, em {
}

small {
    font-size : 70%;
}