.produtos-list {
    margin: $margin-default 0;
    .swiper-slide {
        border: 2px solid transparent;

        &:hover {
            border-color: $color-secondary;
        }

        padding: 20px;
        text-align: center;

        img {
            display: inline-block;
            margin-bottom: 20px;
        }

        a {
            color: $color-secondary;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}