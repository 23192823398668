.logo-positiva {
    @include text-hide;

    width: 19px;
    height: 19px;
    display: inline-block;
    background-image: url('../../img/logo-positiva.png');
}


