$color-light : white;
$color-dark : #000000; 

$color-primary : #cca96f;
$color-primary-dark : darken($color-primary, 50%);
$color-primary-light : lighten($color-primary, 20%);

$color-secondary : #e0e0e0;
$color-secondary-dark : darken($color-secondary, 20%);
$color-secondary-light : lighten($color-secondary, 50%);

$color-gray-5: #333333; //BACKGROND-SECONDARY
$color-gray-4: #4d4d4d; // FONT
$color-gray-3: #626262;
$color-gray-2: #B2BAC4;
$color-gray-1: #eaeaea; //BACKGROND-TERTIARY

$color-error: #E30D0D;
$color-success: #2DCC70;
$color-neutral: #1397E1;

$color-font-dark: $color-gray-4;
$color-font-light: $color-light;

$color-font-primary: $color-primary;
$color-font-secondary: $color-secondary;

$color-background-primary: $color-light;
$color-background-secondary: $color-gray-5;
$color-background-tertiary: $color-gray-1;

@mixin gradient {
    background: -webkit-linear-gradient(45deg, #0072FF 0%, #00BAFF 100%);
    background: -o-linear-gradient(45deg, #0072FF 0%, #00BAFF 100%);
    background: -ms-linear-gradient(45deg, #0072FF 0%, #00BAFF 100%);
    background: -moz-linear-gradient(45deg, #0072FF 0%, #00BAFF 100%);
    background: linear-gradient(45deg, #0072FF 0%, #00BAFF 100%);
}