#page-produtoslinha {

    .linha-banner {
        width: 100%;
        height: 250px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .linha-description {
        @extend .col-12;
        @extend .col-sm-6;
        margin-top: -110px;
        padding: 35px 60px;

        background-color: rgba($color: #0d375c, $alpha: 0.97);

        color: $color-light;

        h1 {
            margin-bottom: 15px;
        }
    }

    .linha-menu-container {
        @extend .col-12;
        @extend .col-sm-6;

        .linha-menu {
            @extend .list-unstyled;
            text-align: right;
            float: right;
        }
        .linha-menu-item {
            border-bottom: 1px solid $color-gray-2;
            padding: 10px 0 10px 25px;
        }
        .linha-menu-link {
            color: $color-gray-4;
        }
    }

}