#page-historia {

    .page-gallery,
    .page-content {
        @extend .col;
        @extend .col-sm-6;
        @extend .no-gutters;
        padding: 0;
    }

    .page-content {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

}

