//-----------------------------------------

$margin-default: 40px;

//-----------------------------------------

html {
    
}

body {
    background-color: $color-background-primary;
}

hr {
    
}

//-----------------------------------------

