#page-premiacoes {

    .page-content {
        @extend .col-sm-8;
    }

}

.produto-premiacoes-list {
    @extend .d-flex;
    @extend .col-12;
    @extend .col-md-8;

    .produto-premiacoes-thumb {
        @extend .w-25;

        img {
            @extend .img-fluid;
        }
    }

    .produto-premiacoes-content {
        @extend .w-75;

        padding-left: 25px;

        h2 {

        }

        ul {
            overflow-y: auto;
            margin-top: 20px;
            max-height: ( 550px - 100px );
        }
    }

    &.produto-premiacoes-left {
        @extend .float-left;

        .produto-premiacoes-thumb {
            @extend .order-0;
        }
        .produto-premiacoes-content {
            padding-left: 25px;
            @extend .order-1;

            h2, ul {
                text-align: left;
            }
        }
    }

    &.produto-premiacoes-right {
        @extend .float-right;

        .produto-premiacoes-thumb {
            @extend .order-1;
        }
        .produto-premiacoes-content {
            padding-right: 25px;
            @extend .order-0;

            h2, ul {
                text-align: right;
            }
        }
    }


}
