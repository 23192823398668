.categoria-list {
    .categoria-item {
        @extend .row;
    }
    .categoria-banner {
        @extend .col-12;
        @extend .col-sm-4;
        @extend .col-md-6;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 1;
    }
    .categoria-content {
        @extend .col-12;
        @extend .col-sm-8;
        @extend .col-md-6;
        box-shadow: 0 0 20px $color-gray-3;
        padding: 25px 10px;
        z-index: 2;

        h2 {
            text-align: center;
        }
    }
}