.linhas-list {
    @extend .row;
    @extend .list-unstyled;
    text-align: center;

    .linhas-item {
        @extend .col;
    }
    .linhas-link {
        width: 360px;
        display: inline-block;
        padding: 30px;
        border-radius: 20px;

        span.btn-primary {
            font-size: 18px;
            background: transparent;
        }

        &:hover {
            background-color: white;
            box-shadow: 0 0 40px $color-gray-3;
            color: $color-light;

            span {
                background-color: $color-primary;
                color: $color-light !important;
            }
        }
    }
    .linhas-image { 
        @extend .img-fluid;
        margin-bottom: 20px;
    }

}


#home-linhas {
    @extend .container-fluid;
    margin-top: $margin-default;
    margin-bottom: $margin-default;
    padding-top: $margin-default;
    padding-bottom: $margin-default;

    background-color: $color-gray-1;
    text-align: center;
    
    h1 {
        color: $color-light;
        font-size: 71px;
    }
}