.pedidos-list {
    @extend .list-unstyled;
    @extend .row;
    @extend .justify-content-center;
    margin-bottom: 30px;

    li {
        @extend .col;

        width: 100%;
        max-width: 200px;
        text-align: center;

        img {
            margin-bottom: 15px;
        }

        input {
            width: 100%;
            text-align: center;
        }
    }
}