.breadcrumb {
    background: transparent;
    padding: 0;
    margin: 0;

    .breadcrumb-item {
        &, a {
            color: $color-gray-3;
            font-family: 'Playfair Display', serif;
            font-style: italic;
            font-size: 12px;
        }
        &.active {

        }
    }
}