#page-produtosdetalhe {

    .produto-banner {
        width: 100%;
        height: 250px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    h1 {
        text-align: right;
        border-bottom: 1px solid $color-font-secondary  ;
        margin-top: 80px;
    }

    .produto-thumb {
        @extend .img-fluid;
    
        @media (min-width: 768px) {
            margin-top: -80px;
        }
    }

    .breadcrumb {
        margin-top: 25px;
        margin-bottom: 40px;
    }

    a.btn,
    button {
        margin-top: 40px;
    }
}

