header {

    @extend .d-flex;
    @extend .justify-content-between;

    // height : 55px;
    // position: absolute;
    width: 100%;

    background-color: $color-light;

    .header-logo {
        margin: 50px;
    }

    .header-right {
        width: 70px;
    }
}