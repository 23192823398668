.mod {
    margin: $margin-default 0;
}

.mod-banner {
    // padding : 15px 0;

    height: 500px;
    background-position: center;
    background-size: cover;

    h2 {
        margin: 0;
    }

    .description {
        margin-top: 35px;
    }

    .btn {
        font-size: 14px;
    }
}

.mod-button {
    .btn-module {
        @extend .btn-secondary;
    }
}

.mod-code {
}

.mod-galeria {
    // li {

    //     width     : 500px;
    //     max-width : 100%;

    //     padding   : 0;
    //     margin    : 10px;

    //     img {
    //         width : 100%;
    //     }
    // }
}

.mod-image {
}

.mod-text {
    text-align: center;

    p {
        text-align: center;
    }
}

.mod-title {
    h2 {
        text-align: center;
        letter-spacing: 2px;
        font-size: 55px;
    }
}

.mod-twocolumns {
    // margin : 80px 0;
    
    text-align: center;
    
    @media (min-width: 768px) {
        
        &.mod-twocolumns-left {
            h2, .description, .description p {
                text-align: left;
            }
        }
        &.mod-twocolumns-right {
            h2, .description, .description p {
                text-align: right;
            }
        }
    
    }
}

.mod-separador {

    // margin : ($margin-default - 5px) 0;

    hr {
        margin: 0;
        height: 55px;
        border: none;
        border-left: 2px solid $color-secondary
    }
}

//--------------------------------------------------------------------------------




