a.btn,
.btn {
    font-size: 16px;
    font-weight: lighter;
    font-style: italic;
    background: none;
    border: none;
    text-align: center;

    border-radius: 5px;
    padding: 10px 15px;
    font-family: 'Playfair Display', serif;

    &:active,
    &:hover,
    &:focus {
        background: none;
    }
}

a.btn-primary,
.btn-primary {
    border: 1px solid $color-primary;
    color: $color-primary !important;
    background: white;

    &:active,
    &:hover,
    &:focus {
        border: 1px solid $color-primary;
        background-color: $color-primary;
        color: $color-light !important;
    }
}

.btn-secondary {
    border: 1px solid $color-secondary;
    color: $color-font-secondary;
    background: $color-primary;

    &:active,
    &:hover,
    &:focus {
        color: $color-font-secondary;
    }
}
    
