.page-header {
    .page-background {
        width: 100%;
        height: 250px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        background-color: #0d375c;
    }

    .page-container {
        @extend .container;
        margin-top: -95px;

        .page-content {
            @extend .col;
            background-color: white;
            padding: 25px 50px !important;
        }
    }
}
