.conquistas-list {
    @extend .list-unstyled;
    margin-top: $margin-default;

    .conquista-item {
        border-bottom: 1px solid $color-gray-3;
        padding: 10px 0;

        //&:last-child {
        //    border: none;
        //}
    }
}