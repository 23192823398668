footer {
    width      : 100%;
    margin-top: $margin-default;
    background-color: $color-background-secondary;
    

    &, a {
        color: $color-gray-1;
    }
    hr {
        border-color: $color-gray-3;
        margin: 0;
    }
    .footer-address {
        @extend .col;
        @extend .d-flex;
        @extend .justify-content-between;
        @extend .list-unstyled;

        padding-top: 65px;
        padding-bottom: (65px - 15px);

        li {
            text-align: center;
            span {
                display: block;
                margin-top: 5px;
            }       
        }
    }
    .footer-copyright {
        @extend .col;
        @extend .d-flex;
        @extend .justify-content-between;

        padding-top: 15px;
        padding-bottom: 15px;
    }   
}