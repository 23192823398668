.home-contato {
    margin-top: -50px;
    padding: (50 + $margin-default) 0 $margin-default;
    background-image: url("../../img/bg-contato.jpg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.home-maps {
    background-color: $color-background-secondary;
    margin-bottom: - $margin-default;
}

.home-historia {
    padding: $margin-default 0;

    text-align: center;

    h2 {
        margin-bottom: 20px;
    }

    a {
        margin-top: 35px;
    }

    img {
        border-radius: 15px;
    }
}