.sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100vh;
    z-index: 999;
    background: $color-light;
    color: $color-font-primary;
    transition: all 0.3s;
    // overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    
    &.active {
        left: 0;
    }

    .sidebar-header {
        padding: 20px;
        background: $color-light;
        
        .sidebar-close {
            width: 35px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            float: right;
            background: $color-light;
            cursor: pointer;
            -webkit-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            
            &:hover {
                background: $color-light;
                color: $color-font-secondary;
            }
        }
    }


    .sidebar-list {
        padding: 20px 0;

        li.sidebar-item {

            a.sidebar-link {
                padding: 10px;
                font-size: 1.1em;
                display: block;
                color: $color-font-primary;

                &:hover {
                    background: $color-light;
                    color: $color-font-secondary;
                }
            }

            &.active > a {
                background: $color-light;
                color: $color-font-secondary;
            }
        }
    }
    
}

.sidebar-collapse {
    @extend .btn;
    border: 1px solid $color-gray-2;
    border-radius: 0;
    margin: 15px;
}

.sidebar-overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &.active {
        display: block;
        opacity: 1;
    }
}
